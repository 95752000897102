import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import {
  CssBaseline,
  Box,
  Button,
  Typography,
  SwipeableDrawer,
  IconButton,
} from '@mui/material';
import Iconify from 'components/Iconify';
import { useLocales } from 'locales';

// Constants
const drawerBleeding = 56;

// Styled Components
const Root = styled('div')(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.background.default,
}));

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: grey[800],
}));

const Puller = styled('div')(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: grey[900],
  borderRadius: 3,
  position: 'absolute',
  top: 8,
  left: 'calc(50% - 15px)',
}));

// Main Component
function SwipeableEdgeDrawer({ window }) {
  const { translate } = useLocales();
  const [open, setOpen] = useState(true);

  const toggleDrawer = (newOpen) => {
    setOpen(newOpen);
  };

  // Define container for iframe scenarios
  const container = window ? () => window().document.body : undefined;

  return (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(90% - ${drawerBleeding}px)`,
            width: '100%',
            overflow: 'visible',
          },
        }}
      />

      {open === false && (
        <Button
          variant="text"
          size="small"
          onClick={() => toggleDrawer(true)}
          sx={{ position: 'fixed', bottom: 0, right: 10, zIndex: 9999 }}
        >
          <Iconify
            icon="game-icons:click"
            sx={{ width: 32, height: 32, cursor: 'handle' }}
            color={'common.white'}
          />
        </Button>
      )}
      {/* Swipeable Drawer */}
      <SwipeableDrawer
        container={container}
        anchor="bottom"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {/* Drawer Header */}
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
          }}
        >
          <Puller />{' '}
          {open === true && (
            <IconButton
              onClick={() => toggleDrawer(false)}
              sx={{
                position: 'absolute',
                top: 8, // Ajustez la position si nécessaire
                right: 8,
                color: 'text.secondary',
              }}
            >
              <Iconify
                icon="line-md:close-circle"
                sx={{ width: 32, height: 32, cursor: 'handle' }}
                color={'common.white'}
              />
            </IconButton>
          )}
          <Typography
            variant="h3"
            sx={{
              p: 2,
              color: 'text.secondary',
              textAlign: 'center',
            }}
          >
            {translate('exceptionopen')}
          </Typography>
        </StyledBox>

        {/* Drawer Content */}
        <StyledBox sx={{ px: 2, pb: 2, height: '100%', overflow: 'auto' }}>
          <Box
            component="img"
            src="/assets/ouverture.jpeg"
            sx={{
              width: 'auto',
            }}
          />
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
